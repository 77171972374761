import React from 'react'
import MyBackgroundImage from "../images/banner.jpg";
import abt from "../images/about-celeb.png";
// ---------------icons--------------
import rings from "../images/wedding-rings.png";
import dance from "../images/tango.png";
import wed from "../images/just-married.png";
import bday from "../images/birthday-cake (1).png";
import newyear from "../images/happy-new-year.png";
import recept from "../images/bride-and-groom.png";
import babyshow from "../images/baby-shower (2).png";
import babyshow1 from "../images/baby-shower (1).png";
import anniver from "../images/gift.png";
// import celeb from "../images/about-celeb.png";
import coworking from "../images/coworking.png";
import party from "../images/bachelorette-party.png";
// -----------------------card images--------------

import Footer from "../components/Footer";
import {MetaTags} from "react-meta-tags";
import "../css/Service.css";
const Service = () => {
  return (
    <div>
         {/* -----------------SEO Start--------------------- */}
         <MetaTags>
        <title>Best  Event Planner in Malad | Dream World Event</title>
        <meta
          name="description"
          content="Discover the Best Event Planner in Malad, crafting unforgettable experiences for all your special occasions. Trust our expertise for seamless celebrations."
        />

        <meta property="og:title" content="Best  Event Planner in Malad | Dream World Event" />
        <meta
          property="og:description"
          content="Discover the Best Event Planner in Malad, crafting unforgettable experiences for all your special occasions. Trust our expertise for seamless celebrations."
        />
        <meta
          name="keywords"
          content="Event Organizers in Malad,
          Wedding Planners in Malad,
          Best  Event Planner in Malad,
          Decorator near Malad,
          Birthday Party Planners in Malad"
        />
        <link rel="canonical" href="/best-event-planner-malad" />
      </MetaTags>
      {/* -----------------SEO End--------------------- */}
  <div
  className="breadcrumb-option set-bg"
  data-setbg=""
  style={{ backgroundImage: `linear-gradient(to right, rgba(10, 9, 9, 0.5), rgb(0, 0, 0, 0.5)),url(${MyBackgroundImage})` }}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <div className="breadcrumb__text">
          <h1 className='d-none'>Best  Event Planner in Malad</h1>
          <h2>Services </h2>
          <div className="breadcrumb__links">
            <a href="./index.html">Home</a>
            <span>/&nbsp;&nbsp;&nbsp;&nbsp;Services</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* ---------------------------------------Services section-------------------- */}
<section className="eventTypes">
  <div className="container">
    <div className="type-title wide-100">
      <h2 data-aos="fade-down">Your Event, Our Passion</h2>
      <p>Our dedicated team specializes in orchestrating a diverse range of events, each crafted with meticulous attention to detail and a passion for excellence. From grand corporate galas that leave a lasting impression, to intimate weddings filled with love and warmth, to unforgettable birthday celebrations, our expertise spans the entire spectrum of events.
 </p>
    </div>
    <div className="row  eventList">
      {/* <div class=""> */}
      <div className="col-lg-3 col-md-4 col-6">
        <ul className="m-0 p-0">
          <li>
            <div className="serves">
              <div className="servIcon">
                <img
                  src={rings}
                  alt="wedding rings"
                />
              </div>
              <span>Ring ceremeony</span>
            </div>
          </li>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img
                  src={dance}
                  alt="wedding-arch"
                />
              </div>
              <span>Sangeet</span>
            </div>
          </li>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img
                  src={wed}
                  alt="bride and groom"
                />
              </div>
              <span>Wedding</span>
            </div>
          </li>
        </ul>
      </div>
      <div className="col-lg-3 col-md-4 col-6">
        <ul className='m-0 p-0'>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img src={anniver} alt="anniversary" />
              </div>
              <span>Anniversary</span>
            </div>
          </li>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img src={recept} alt="dance" />
              </div>
              <span>Reception</span>
            </div>
          </li>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img
                  src={bday}
                  alt="birthday cake"
                />
              </div>
              <span>Birthday</span>
            </div>
          </li>
        </ul>
      </div>
      <div className="col-lg-3 col-md-4 col-6">
        <ul className='m-0 p-0'>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img
                  src={babyshow1}
                  alt="baby shower"
                />
              </div>
              <span>Baby Shower</span>
            </div>
          </li>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img
                  src={babyshow}
                  alt="baby shower Logo"
                />
              </div>
              <span>Baby-sprinkle</span>
            </div>
          </li>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img src="assets/img/dish/kalasha.png" alt="kalasha" />
              </div>
              <span>Pooja</span>
            </div>
          </li>
        </ul>
      </div>
      <div className="col-lg-3 col-md-4 col-6">
        <ul className='m-0 p-0'>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img
                  src={newyear}
                  alt="happy-new-year"
                />
              </div>
              <span>New Year</span>
            </div>
          </li>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img
                  src={party}
                  alt="party dancing"
                />
              </div>
              <span>Get Together</span>
            </div>
          </li>
          <li>
            <div className="serves">
              <div className="servIcon">
                <img src={coworking} alt="coworking" />
              </div>
              <span>corporate Events</span>
            </div>
          </li>
        </ul>
      </div>
      {/* </div> */}
    </div>
  </div>
</section>
     {/* ----------------------------events section--------------------------------- */}

<section>
    <div className="container">
    <div
  id="inner-page-about"
  className="row wpb_row vc_row-fluid wide-60 division"
>
 
  <div className="wpb_column vc_column_container col-12 col-lg-7 col-md-8 col-12">
    <div className="vc_column-inner vc_custom_1526265638830">
      <div className="wpb_wrapper">
        <div className="about-txt m-bottom-40 p-right-30 ">
          <div>
          <span className="section-id">Solutions
</span>
            <h4 className="h4-lg ">Problem-Solving</h4>
            <p> At Dreamworld Events , we thrive on problem-solving. We understand that in the world of event planning, unforeseen challenges can arise at any moment. That's where our problem-solving expertise comes into play. Our team is equipped with the skills, creativity, and experience necessary to unravel any challenge and turn it into an opportunity for success. Whether it's a sudden change in the weather, unexpected logistical issues , we have the solutions that ensure your event runs seamlessly.</p>
          </div>
          <div>
           
          </div>
          <div />
          
          
        </div>
      </div>
    </div>
  </div>
  <div className="wpb_column vc_column_container col-sm-12 col-lg-5 col-md-4 col-12">
    <div className="aboutImg">
        <img src={abt} alt="hall decoration"/>
    </div>
  </div>
</div>
    </div>
</section>

{/* ---------------------------services--------------------------- */}
<section className="service-grid pb-5 pt-5">
  <div className="container">
    <div className="row">
      <div className="col-xl-12 text-center mb-4">
        <div className="service-title">
          <h4>Our Services</h4>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4 col-md-6 text-center mb-3">
        <div className="service-wrap" id="serv1">
          <div className="service-icon">
            <i className="fa fa-birthday-cake" />
          </div>
          <h4>Customize Balloon Decor</h4>
          <p>
          At Dreamworld Events , we thrive on problem-solving. We understand that in the world of event planning, unforeseen challenges can arise at any moment. That's where our problem-solving expertise comes into play. Our team is equipped with the skills, creativity, and experience necessary to unravel any challenge and turn it into an opportunity for success. 
          </p>
         
        </div>
      </div>
      <div className="col-lg-4 col-md-6 text-center mb-3">
        <div className="service-wrap" id="serv2">
          <div className="service-icon">
            <i className="fa fa-snowflake-o" />
          </div>
          <h4>Customize Flower Decor</h4>
          <p>
          Best Event Planner in Malad Services elevates your event with the timeless beauty and fragrance of flowers. Our customized flower decor service is designed to infuse your venue with the colors and scents that reflect your style and vision, creating a captivating floral ambiance that enhances your special day.

          </p>
         
        </div>
      </div>
      <div className="col-lg-4 col-md-6 text-center mb-3">
        <div className="service-wrap" id="serv3">
          <div className="service-icon">
            <i className="fa fa-bank" />
          </div>
          <h4>Haldi & Mehandi Decor</h4>
          <p>
          Best Event Planner in Malad Services adds traditional charm and vibrancy to your pre-wedding ceremonies with our Haldi and Mehendi decor services. We specialize in creating immersive and culturally rich setups that make these rituals even more joyous and memorable.
          </p>
          
        </div>
      </div>
      <div className="col-lg-4 col-md-6 text-center mb-3">
        <div className="service-wrap" id="serv4">
          <div className="service-icon">
            <i className="fa fa-paint-brush" />
          </div>
          <h4>Provide Artist</h4>
          <p>
          Best Event Planner in Malad Services enriches your event with the talents of skilled artists. We offer a diverse range of artists, from painters and musicians to entertainers and performers, who will captivate your guests and leave a lasting impression.

          </p>
   
        </div>
      </div>
      <div className="col-lg-4 col-md-6 text-center mb-3">
        <div className="service-wrap" id="serv5">
          <div className="service-icon">
            <i className="fa fa-users" />
          </div>
          <h4>MICE</h4>
          <p>
          Best Event Planner in Malad Services from meticulously organized corporate meetings to dynamic exhibitions, our MICE services cater to the unique needs of businesses. We ensure that your events are seamless, engaging, and deliver on your objectives.
          </p>
        
        </div>
      </div>
      <div className="col-lg-4 col-md-6 text-center mb-3">
        <div className="service-wrap" id="serv6">
          <div className="service-icon">
            <i className="fa fa-glass" />
          </div>
          <h4>Theme Party</h4>
          <p>
          Best Event Planner in Malad Services lets your imagination run wild and allow us to bring your dream theme to life. Our theme party service creates a world tailored to your desires, ensuring that every detail aligns with your chosen theme, making it an event to remember.

          </p>
        
        </div>
      </div>
    </div>
  </div>
</section>


<Footer/>
    </div>
  )
}

export default Service