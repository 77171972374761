
////////////// Door Images //////////////////

import wed1 from "../images/gallery/jhula.jpg";
import wed2 from "../images/gallery/haldiset.jpg";
import wed3 from "../images/gallery/engagementstage.jpg";
import wed4 from "../images/gallery/sittingarea.jpeg";
import wed5 from "../images/gallery/decor2.jpg";
import classSet1 from "../images/gallery/vidhimandap.jpg";

import conferenceSet from "../images/anniversary.avif";
import baby01 from "../images/gallery/babyshower.jpeg";
import baby02 from "../images/gallery/baby5.jpeg";
import baby03 from "../images/gallery/baby2.jpeg";
import baby04 from "../images/gallery/baby3.jpg";
import baby05 from "../images/gallery/baby4.jpeg";


////////////// Hall Images //////////////////

import Hall01 from "../images/gallery/ghosttheme.jpeg";
import Hall02 from "../images/gallery/halloweentheme.jpeg";


////////////// BedRoom Images //////////////////

import engage from '../images/gallery/stage1.jpg';
// import BedRoom02 from '../images/gallery/decor2.jpg';
// import BedRoom03 from '../images/gallery/decor2.jpg';


////////////// BedRoom Images //////////////////

import mehandi from "../images/gallery/mehendiset.jpg";
import haldi from "../images/gallery/haldigarden.jpeg";
import Kitchen03 from "../images/gallery/mehendiset.jpg";
import Kitchen04 from "../images/gallery/mehendiset.jpg";



////////////// Temple Images //////////////////


import Temple01 from "../images/gallery/vidhimandap.jpg";
import Temple02 from "../images/gallery/vidhimandap.jpg";
import Temple03 from "../images/gallery/vidhimandap.jpg";
import Temple04 from "../images/gallery/vidhimandap.jpg";
import Temple05 from "../images/gallery/vidhimandap.jpg";


const GalleryData = [
  {
    id: 1,
    Category:"Wedding Decor",
    CategoryName:"wedding",
    CImage:classSet1,
  },
  {
    id: 2,
    Category:"Corporate event",
    CategoryName:"Main Door",
    CImage:conferenceSet,
  },
  {
    id: 3,
    Category:"Functions",
    CategoryName:"Mehandi",
    CImage:mehandi,
  },
  {
    id: 4,
    Category:"Wedding Decor",
    CategoryName:"wedding",
    CImage:wed1,
  },
  {
    id: 5,
    Category:"Baby Shower",
    CategoryName:"baby",
    CImage:baby01,

  },
  {
    id:6,
    Category:"Engagement decor",
    CategoryName:"Hall",
    CImage:engage,
  },
  {
    id: 7,
    Category:"Birthday theme decor",
    CategoryName:"Hall",
    CImage:Hall02,
  },
  {
    id: 8,
    Category:"Monsoon shade",
    CategoryName:"Hall",
    CImage:wed5,
  },
  {
    id: 9,
    Category:"Wedding Decor",
    CategoryName:"wedding",
    CImage:wed5,
  },
  {
    id: 10,
    Category:"Wedding Decor",
    CategoryName:"wedding",
    CImage:wed2,
  },
  {
    id: 11,
    Category:"Wedding Decor",
    CategoryName:"wedding",
    CImage:wed3,
  },
  {
    id: 12,
    Category:"Wedding Decor",
    CategoryName:"wedding",
    CImage:wed4,
  },
  {
    id: 13,
    Category:"Baby Shower",
    CategoryName:"baby",
    CImage:baby02,
  },
  {
    id: 14,
    Category:"Baby Shower",
    CategoryName:"baby",
    CImage:baby03,
  },
  {
    id: 15,
    Category:"Baby Shower",
    CategoryName:"baby",
    CImage:baby04,
  },
  {
    id: 16,
    Category:"Baby Shower",
    CategoryName:"baby",
    CImage:baby05,
  },
  {
    id: 17,
    Category:"Birthday theme decor",
    CategoryName:"Hall",
    CImage:Hall01,
  },
  {
    id: 18,
    Category:"Birthday theme decor",
    CategoryName:"Hall",
    CImage:Hall02,
  },
  {
    id: 19,
    Category:"Functions",
    CategoryName:"haldi",
    CImage:haldi,
  },
  // {
  //   id: 20,
  //   Category:"BedRoom",
  //   CategoryName:"Bed Room",
  //   CImage:BedRoom11,
  // },
  // {
  //   id: 21,
  //   Category:"BedRoom",
  //   CategoryName:"Bed Room",
  //   CImage:BedRoom12,
  // },
  // {
  //   id: 22,
  //   Category:"BedRoom",
  //   CategoryName:"Bed Room",
  //   CImage:BedRoom13,
  // },
  // {
  //   id: 23,
  //   Category:"BedRoom",
  //   CategoryName:"Bed Room",
  //   CImage:BedRoom14,
  // },
  // {
  //   id:24,
  //   Category:"BedRoom",
  //   CategoryName:"Bed Room",
  //   CImage:BedRoom15,
  // },
  // {
  //   id: 25,
  //   Category:"BedRoom",
  //   CategoryName:"Bed Room",
  //   CImage:BedRoom16,
  // },
  // {
  //   id: 26,
  //   Category:"Kitchen",
  //   CategoryName:"Kitchen",
  //   CImage:Kitchen01,
  // },
  // {
  //   id: 27,
  //   Category:"Kitchen",
  //   CategoryName:"Kitchen",
  //   CImage:Kitchen02,
  // },
  // {
  //   id: 28,
  //   Category:"Kitchen",
  //   CategoryName:"Kitchen",
  //   CImage:Kitchen03,
  // },
  // {
  //   id: 29,
  //   Category:"Kitchen",
  //   CategoryName:"Kitchen",
  //   CImage:Kitchen04,
  // },
  // {
  //   id: 30,
  //   Category:"Kitchen",
  //   CategoryName:"Kitchen",
  //   CImage:Kitchen05,
  // },
  // {
  //   id: 31,
  //   Category:"Kitchen",
  //   CategoryName:"Kitchen",
  //   CImage:Kitchen06,
  // },
  // {
  //   id: 32,
  //   Category:"Kitchen",
  //   CategoryName:"Kitchen",
  //   CImage:Kitchen07,
  // },
  // {
  //   id: 33,
  //   Category:"Kitchen",
  //   CategoryName:"Kitchen",
  //   CImage:Kitchen08,
  // },
  // {
  //   id: 34,
  //   Category:"BathRoom",
  //   CategoryName:"BathRoom",
  //   CImage:BathRoom01,
  // },
  // {
  //   id: 35,
  //   Category:"BathRoom",
  //   CategoryName:"BathRoom",
  //   CImage:BathRoom02,
  // },
  // {
  //   id: 36,
  //   Category:"BathRoom",
  //   CategoryName:"BathRoom",
  //   CImage:BathRoom03,
  // },
  // {
  //   id: 37,
  //   Category:"BathRoom",
  //   CategoryName:"BathRoom",
  //   CImage:BathRoom04,
  // },
  // {
  //   id: 38,
  //   Category:"BathRoom",
  //   CategoryName:"BathRoom",
  //   CImage:BathRoom05,
  // },
  // {
  //   id: 39,
  //   Category:"BathRoom",
  //   CategoryName:"BathRoom",
  //   CImage:BathRoom06,
  // },
  // {
  //   id: 40,
  //   Category:"Tv Unit",
  //   CategoryName:"Tv Unit",
  //   CImage:TvUnit01,
  // },
  // {
  //   id: 41,
  //   Category:"Tv Unit",
  //   CategoryName:"Tv Unit",
  //   CImage:TvUnit02,
  // },
  // {
  //   id: 42,
  //   Category:"Tv Unit",
  //   CategoryName:"Tv Unit",
  //   CImage:TvUnit03,
  // },
  // {
  //   id: 43,
  //   Category:"Tv Unit",
  //   CategoryName:"Tv Unit",
  //   CImage:TvUnit04,
  // },
  // {
  //   id: 44,
  //   Category:"Tv Unit",
  //   CategoryName:"Tv Unit",
  //   CImage:TvUnit05,
  // },
  // {
  //   id: 45,
  //   Category:"Tv Unit",
  //   CategoryName:"Tv Unit",
  //   CImage:TvUnit06,
  // },
  // {
  //   id: 46,
  //   Category:"Tv Unit",
  //   CategoryName:"Tv Unit",
  //   CImage:TvUnit07,
  // },
  // {
  //   id: 47,
  //   Category:"False Ceiling",
  //   CategoryName:"False Ceiling",
  //   CImage:FalseCeiling01,
  // },
  // {
  //   id: 48,
  //   Category:"False Ceiling",
  //   CategoryName:"False Ceiling",
  //   CImage:FalseCeiling02,
  // },
  // {
  //   id: 49,
  //   Category:"False Ceiling",
  //   CategoryName:"False Ceiling",
  //   CImage:FalseCeiling03,
  // },
  // {
  //   id: 50,
  //   Category:"False Ceiling",
  //   CategoryName:"False Ceiling",
  //   CImage:FalseCeiling04,
  // },
  // {
  //   id: 51,
  //   Category:"False Ceiling",
  //   CategoryName:"False Ceiling",
  //   CImage:FalseCeiling05,
  // },
  // {
  //   id: 52,
  //   Category:"False Ceiling",
  //   CategoryName:"False Ceiling",
  //   CImage:FalseCeiling06,
  // },
  
];

export default GalleryData;

