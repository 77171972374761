import React from 'react'
import MyBackgroundImage from "../images/banner.jpg";
import Footer from '../components/Footer';
import $ from "jquery";

import "../css/Contact.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {MetaTags} from "react-meta-tags";
const Contact = () => {
  return (
    <div>
{/* -----------------SEO Start--------------------- */}
<MetaTags>
        <title>Birthday Party Planners in Malad | Dream World Event</title>
        <meta
          name="description"
          content="Discover the best Birthday Party Planners in Malad to make your special day truly unforgettable. Contact us now to plan your perfect birthday party."
        />

        <meta property="og:title" content="Birthday Party Planners in Malad | Dream World Event" />
        <meta
          property="og:description"
          content="Discover the best Birthday Party Planners in Malad to make your special day truly unforgettable. Contact us now to plan your perfect birthday party."
        />
        <meta
          name="keywords"
          content="Event Organizers in Malad,
          Wedding Planners in Malad,
          Best  Event Planner in Malad,
          Decorator near Malad,
          Birthday Party Planners in Malad"
        />
        <link rel="canonical" href="/birthday-party-planners-malad" />
      </MetaTags>
      {/* -----------------SEO End--------------------- */}

     <div
  className="breadcrumb-option set-bg"
  data-setbg=""
  style={{ backgroundImage: `linear-gradient(to right, rgba(10, 9, 9, 0.5), rgb(0, 0, 0, 0.5)),url(${MyBackgroundImage})` }}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <div className="breadcrumb__text">
          <h1 className='d-none'>Birthday Party Planners in Malad</h1>
          <h2>Contact Us </h2>
          <div className="breadcrumb__links">
            <a href="./index.html">Home</a>
            <span>Contact Us</span>
            <p className="d-none">Birthday Party Planners in Malad understands that effective communication is the cornerstone of successful event planning. We're here to make your event dreams come true and provide you with the support you need. Whether you're looking to discuss your upcoming event, request a quote, or have a simple question, we welcome the opportunity to connect with you.


Birthday Party Planners in Malad are passionate about crafting unforgettable events, and we're equally passionate about building connections with our clients. Your questions, ideas, and feedback are not only welcome but highly valued. Whether you're envisioning a grand wedding, a corporate gala, a milestone birthday celebration, or any other special occasion, our dedicated team is eager to assist you at every stage of the planning process.

Birthday Party Planners in Malad believes that every remarkable event begins with a conversation, and we're excited to initiate that conversation with you. Don't hesitate to get in touch with us; together, we can bring your vision to life and create a truly extraordinary event. Thank you for considering Dreamworld Events as your trusted event partner.

</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section className="contact_us">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="contact_inner">
            <div className="row">
              <div className="col-lg-10 col-md-8">
                <div className="contact_form_inner">
                  <div className="contact_field">
                    <h3>Contact Us</h3>
                    <p>
                    Get in touch with us today and let's start creating your unforgettable event together.

                    </p>
                    <Formik
                      initialValues={{ email: "", Name: "", Cnumber: "",  message: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.Name) {
                          errors.Name = "Required";
                        } else if (values.Name.length >= 10) {
                          errors.Name = "Enter A Name";
                        }

                        if (!values.Cnumber) {
                          errors.Cnumber = "Required";
                        } else if (values.Cnumber.length >= 11) {
                          errors.Name = "Invalid Contact Number";
                        }

                        if (!values.email) {
                          errors.email = "Required";
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        // setTimeout(() => {

                        var body =
                          '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Dreamworld Events Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' +  values.Name + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' +  values.email + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' +  values.Cnumber + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' +  values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Dreamworld Events</p></div></body></html>';

                        $.post(
                          "https://skdm.in/server/v1/send_lead_mail.php",
                          {
                          
                            toEmail: "dreamworldevents00@gmail.com",
                            fromEmail: "skdmlead@gmail.com",
                            bccMail: "skdmlead@gmail.com",
                            mailSubject: "New Lead genration",
                            mailBody: body,
                            accountName: "dreamworldevents",
                            accountLeadSource:
                              "",
                          },

                          function (dataa, status) {
                            // console.log('data :' + dataa);
                            // console.log("name:" + custName);
                          }
                        );

                        alert(
                          "Your Form has Submitted Our team will contact with You  soon."
                        );

                        // e.preventDefault()

                        setSubmitting(false);
                          // Reset the form after submission
  resetForm();

                        // }, 10);
                      }}
                    >
                      <Form>
                    <Field
                      name="Name"
                      type="text"
                      className="form-control form-group"
                      placeholder="Name"
                    />
                     <ErrorMessage name="Name" component="div" />
                     <Field
                     name="Cnumber"
                      type="number"
                      className="form-control form-group"
                      placeholder="Phone"
                    />
                      <ErrorMessage name="Cnumber" component="div" /> 
                    <Field
                    name="email"
                      type="text"
                      className="form-control form-group"
                      placeholder="Email"
                    />
                     <ErrorMessage name="email" component="div" />
                    <Field  as="textarea"
                     name="message"
                      className="form-control form-group"
                      placeholder="Message"
                      defaultValue={""}
                    />
                      <ErrorMessage name="message" component="div" />
                    <button className="contact_form_submit">Send</button>
                    </Form>
              </Formik>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="right_conatct_social_icon d-flex align-items-end">
                  <div className="socil_item_inner d-flex">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=61552052639546">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/dreamworldevents0/">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google-plus" />
                      </a>
                    </li>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact_info_sec d-lg-block d-md-block d-none">
              <h4>Contact Info</h4>
              <div className="d-flex info_single align-items-center">
                <i className="fa fa-phone" />
                <span>9004939260 / 8779038121 / 9967105710</span>
              </div>
              <div className="d-flex info_single align-items-center">
                <i className="fa fa-envelope" />
                <span>dreamworldevents00@gmail.com</span>
              </div>
              <div className="d-flex info_single align-items-center">
                <i className="fa fa-map-marker" />
                <span>
                Shanti Park Hall, Poddar Road, Near Gol Garden, Opp. Nirman Lab, Malad (E),Mumbai-97.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="map_sec">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="map_inner">
            <h4>Find Us on Google Map</h4>
            <p>
            Our map page provides you with the convenience of pinpointing our address, ensuring that you can navigate to our event location effortlessly.

            </p>
            {/* <div className="map_bind">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471220.5631094339!2d88.04952462217592!3d22.6757520733225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1596988408134!5m2!1sen!2sin"
                width="100%"
                height={450}
                frameBorder={0}
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex={0}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </section>
<Footer/>
    </div>
  )
}

export default Contact