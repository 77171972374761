import React from 'react';
import "../css/Tabs.css";
// import Zoom from 'react-reveal/Zoom';
const Tabs = ({ filterCategory, tabsData }) => {


    return (
        <>
            <div className='Tabs-container'>
                {/* <Zoom right cascade> */}
                    <div className="text-center">
                        
                            <div className="tablist">

      
                                {
                            tabsData.map((category, index) => {
                                return (
                                    // <ul class="inner">
                                    // <li class="tablist__tab" onClick={() => filterCategory(category)} key={index} >{category}</li>
                                     <button type="button" className="text-capitalize ButtonG tablist__tab" onClick={() => filterCategory(category)} key={index} >{category}</button>
                                    // </ul>
                                )
                            })
                        }
                      
                            </div>
                        
                    </div>
                {/* </Zoom> */}
            </div>
        </>
    )
}

export default Tabs