import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from "./components/Navbar"
import Home from './pages/Home';
import About from './pages/About';
import Service from './pages/Service';
import Gallery from "./pages/Gallery";
// import Royalfarm from './pages/Royalfarm';
import Contact from './pages/Contact';

function App() {
  return (
    <BrowserRouter>
    <NavBar/>
      <Routes>
        <Route path='/' element={<Home/>}/> 
         <Route path='/wedding-planners-malad' element={<About/>}/>
   
         <Route path='/best-event-planner-malad' element={<Service/>} />
         <Route path='/decorator-near-malad' element={<Gallery/>} />
         <Route path='/birthday-party-planners-malad' element={<Contact/>} /> 

      </Routes>
    </BrowserRouter>
  );
}

export default App;
