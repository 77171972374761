import React, { useState } from "react";
import "../css/Navbar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/Dream World Events.png";
const NavBar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <div>
      <section className="NavSection">
        <div className="container-fluid">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
          >
            <Container>
              <Navbar.Brand href="/">
                <img src={logo} alt="" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="main-nav">
                <Nav>
                 
                <Nav.Link
                href="/"
                className="nav-link"
              >  <Link
              to="/"
              className="nav-link"
            >  Home
            </Link>
            
              </Nav.Link>
              
                
                    <Nav.Link  className="nav-link" href="/wedding-planners-malad"> <Link  className="nav-link" to="/wedding-planners-malad">About Us</Link>
                 </Nav.Link>
                   
                    <Nav.Link  className="nav-link"  href="/best-event-planner-malad"> <Link  className="nav-link"  to="/best-event-planner-malad">Services</Link></Nav.Link>
                   
                
                    <Nav.Link  className="nav-link"  href="/decorator-near-malad">                 
                    <Link  className="nav-link"  to="/decorator-near-malad">Gallery</Link></Nav.Link>

                 
                    <Nav.Link  className="nav-link"  href="/birthday-party-planners-malad"> <Link  className="nav-link"  to="/birthday-party-planners-malad">Contact Us</Link></Nav.Link>
                   
                 
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </section>

    </div>
  )
}

export default NavBar