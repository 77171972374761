import React from "react";
import "../css/Home.css";
import {Link} from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import $ from 'jquery';
import process from "../images/gallery/jhula.jpg"
import MyBackgroundImage from "../images/banner.jpg";
import abt1 from "../images/about-celeb.png";
import abt2 from "../images/gallery/jhula.jpg";
import babyshower from "../images/gallery/babyshower.jpeg"
import theme from "../images/gallery/theme2.jpeg";
import Footer from "../components/Footer";
import haldi from "../images/gallery/haldiset.jpg";
import mehandi from "../images/gallery/mehendiset.jpg";
import jhula from "../images/gallery/jhula.jpg";
import vidhi from "../images/gallery/vidhimandap.jpg";
import engage from "../images/gallery/engagementstage.jpg";
import wed from "../images/gallery/stage1.jpg";
import { MetaTags } from "react-meta-tags";
const Home = () => {
  return (
    <div>
      {/* -----------------SEO Start--------------------- */}
      <MetaTags>
        <title>Event Organizers in Malad | Dream World Event</title>
        <meta
          name="description"
          content="Looking for professional Event Organizers in Malad? Our expert event planning team in Malad specializes in creating unforgettable experiences. "
        />

        <meta property="og:title" content="Event Organizers in Malad | Dream World Event" />
        <meta
          property="og:description"
          content="Looking for professional Event Organizers in Malad? Our expert event planning team in Malad specializes in creating unforgettable experiences. "
        />
        <meta
          name="keywords"
          content="Event Organizers in Malad,
          Wedding Planners in Malad,
          Best  Event Planner in Malad,
          Decorator near Malad,
          Birthday Party Planners in Malad"
        />
        <link rel="canonical" href="/event-organisers-malad" />
      </MetaTags>
      {/* -----------------SEO End--------------------- */}
      <div className="wpb_wrapper">
        <section id="hero-2" className="bg-scroll hero-section division ">
          <div className="container">
            <div className="row">
              {/* HERO TEXT */}
              <div className="col-md-9 col-lg-8 offset-md-1 offset-lg-1">
                {/* Square Brackets */}
                <div className="square">
                  <div className="inner" />
                </div>
                {/* Event Description */}
                <div className="hero-txt white-color">
                  <h1 className="d-none">Event Organizers in Malad</h1>
                  <h3>Many Years of Expertise</h3>
                  <h2>Dreamworld Events</h2>
                  <p>
                  your premier event planning company, dedicated to turning your dreams into unforgettable experiences and

                    <br />
                    crafts events that leave a lasting impression.
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>{" "}
          {/* End container */}
        </section>{" "}
        {/* END HERO-2 */}
      </div>
      {/* --------------------------------about section------------------- */}

      <section>
        <div className="container">
          <div className="vc_row wpb_row vc_row-fluid wide-100">
            <div className="wpb_wrapper">
              <div className="row ">
                <div className="col-lg-5 col-12">
                  <div className="about-txt m-bottom-10">
                    <span className="section-id">Event Decorators</span>
                    <h2 className="h2-medium">Dreamworld Events</h2>
                  </div>
                  <p>
                  Event organizers in Malad specialize in turning ordinary events into extraordinary experiences. With a passion for creativity and an eye for detail, we are your go-to event decorator for all of life's special moments. Whether you're planning a wedding, a corporate gala, a birthday celebration, or any other significant event, we are here to transform your vision into a breathtaking reality.

                  </p>
                  <p>
                  Event organizers in Malad are a team of experienced and dedicated event decorators committed to making your event truly unforgettable. We take pride in our ability to conceptualize, design, and execute stunning decor that reflects your unique style and personality. No event is too big or small for us, and we cater to a wide range of themes and styles, from classic elegance to modern chic and everything in between.


                  </p>
                  <p className="d-none">
                  Event organizers in Malad  are constantly pushing the boundaries of design, bringing fresh and innovative ideas to the table. We stay on top of the latest trends to ensure your event is up-to-date and visually stunning. We believe that the smallest details can make the biggest impact. 

                  </p>
                  <p className="d-none"> Event organizers in Malad work closely with you to create a customized decor plan that perfectly aligns with your vision, preferences and budget.


Event organizers in Malad are committed to delivering on time and within budget, while providing the highest level of service and professionalism throughout the entire process.
</p>
                  {/* </div> */}
                </div>
                <div className="col-lg-7  col-12">
                  <div className="row">
                    <div className="col-md-6 m-0 p-0">
                      <div className="about-img">
                        <img
                          className=""
                          src={abt1}
                          alt="mission of gokul events"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 m-0 p-0">
                    <div className="about-img1">
                        <h2>Mission</h2>
                        <p>
                        At Dreamworld Events, our mission is to create extraordinary and memorable experiences that exceed our clients' expectations. We are committed to executing events that reflect our clients' unique visions, leaving a lasting impact on attendees and fostering cherished memories.
                        </p>
                      </div> 
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 m-0 p-0">
                      <div className="about-img2">
                        <h2>vision</h2>
                        <p>
                        Dreamworld Events vision is to be the industry's leading event company, renowned for innovation, creativity, and exceptional service. We aspire to consistently raise the bar in event planning, setting new standards of excellence and becoming the go-to partner for individuals and organizations seeking to bring their event dreams to life.

                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 m-0 p-0">
                      <div className="about-img">
                        <img
                          className="img-fluid"
                          src={abt2}
                          alt="vision of gokul events"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="about-img">
                    <img
                      className="img-fluid"
                      src={abt1}
                      alt="Randon Pexon, Organizer"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ------------------------gallery section------------------------ */}
      <section>
        <div className="container">
          <div className="row">
            <div id="speakers" className="vc_row wpb_row vc_row-fluid wide-100">
              <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-10">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <div className="section-title ">
                      <span className="section-id">Glimpse of Events</span>
                      <h2 className="banner-regular">
                        Events & Decoration....
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <div className="ova_speaker  speakers-section division style2">
                      <div className="row">
                        <div className="col-md-6 col-lg-3">
                          <div className="speaker">
                           
                              <div className="hover-overlay">
                                <img className="img-fluid" src={haldi} alt="haldi decoration" />
                              </div>
                              <div className="speaker-meta m-top-20 m-bottom-30">
                                <h5 className="h5-lg ">Haldi</h5>
                                {/* <p className=" grey-color">Founder &amp; Ex.Chairman</p>
                    <span className=" grey-color">Company Name</span> */}
                              </div>
                         
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3  ">
                          <div className="speaker">
                            
                              <div className="hover-overlay">
                                <img
                                  className="img-fluid"
                                  src={mehandi}
                                  alt="mehandi decoration"
                                />
                              </div>
                              <div className="speaker-meta m-top-20 m-bottom-30">
                                <h5 className="h5-lg ">Mehandi</h5>
                                {/* <p className=" grey-color">Founder &amp; Ex.Chairman</p>
                    <span className=" grey-color">Company Name</span> */}
                              </div>
                            
                          </div>
                        </div>{" "}
                        <div className="col-md-6 col-lg-3  ">
                          <div className="speaker">
                           
                              <div className="hover-overlay">
                                <img
                                  className="img-fluid"
                                  src={engage}
                                  alt="engagement setup"
                                />
                              </div>
                              <div className="speaker-meta m-top-20 m-bottom-30">
                                <h5 className="h5-lg ">Engagement</h5>
                                {/* <p className=" grey-color">Founder &amp; Ex.Chairman</p>
                    <span className=" grey-color">Company Name</span> */}
                              </div>
                            
                          </div>
                        </div>{" "}
                        <div className="col-md-6 col-lg-3  ">
                          <div className="speaker">
                           
                              <div className="hover-overlay">
                                <img
                                  className="img-fluid"
                                  src={jhula}
                                  alt="Jhula decor"
                                />
                              </div>
                              <div className="speaker-meta m-top-20 m-bottom-30">
                              <h5 className="h5-lg ">Swing Decor</h5>
                              </div>
                          
                          </div>
                        </div>{" "}
                        <div className="col-md-6 col-lg-3  ">
                          <div className="speaker">
                           
                              <div className="hover-overlay">
                                <img
                                  className="img-fluid"
                                  src={vidhi}
                                  alt="vidhi mandap decoration"
                                />
                              </div>
                              <div className="speaker-meta m-top-20 m-bottom-30">
                                <h5 className="h5-lg ">Vidhi Madap</h5>
                                {/* <p className=" grey-color">Founder &amp; Ex.Chairman</p>
                    <span className=" grey-color">Company Name</span> */}
                              </div>
                           
                          </div>
                        </div>{" "}
                        <div className="col-md-6 col-lg-3  ">
                          <div className="speaker">
                            
                              <div className="hover-overlay">
                                <img
                                  className="img-fluid"
                                  src={babyshower}
                                  alt="Baby Shower"
                                />
                              </div>
                              <div className="speaker-meta m-top-20 m-bottom-20">
                                <h5 className="h5-lg ">Baby Shower</h5>
                                {/* <p className=" grey-color">Founder &amp; Ex.Chairman</p>
                    <span className=" grey-color">Company Name</span> */}
                              </div>
                           
                          </div>
                        </div>{" "}
                        <div className="col-md-6 col-lg-3  ">
                          <div className="speaker">
                            
                              <div className="hover-overlay">
                                <img className="img-fluid" src={theme} alt="flower decoration" />
                              </div>
                              <div className="speaker-meta m-top-20 m-bottom-30">
                                <h5 className="h5-lg ">Theme Party</h5>
                             
                              </div>
                            
                          </div>
                        </div>{" "}
                        <div className="col-md-6 col-lg-3  ">
                          <div className="speaker">
                            
                              <div className="hover-overlay">
                                <img
                                  className="img-fluid"
                                  src={wed}
                                  alt="Jonathan Barnes"
                                />
                              </div>
                              <div className="speaker-meta m-top-20 m-bottom-20">
                                <h5 className="h5-lg ">wedding</h5>
                               
                              </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -----------------------------counter section------------- */}
      <section
        id="banner-1"
        data-vc-full-width="true"
        data-vc-full-width-init="true"
        data-vc-parallax="1.5"
        className="vc_row wpb_row vc_row-fluid vc_custom_1526457611366 vc_row-has-fill vc_general vc_parallax vc_parallax-content-moving wide-100"
        style={{
          backgroundImage: `linear-gradient(90deg,rgb(221 51 51 / 70%), rgb(221 51 51 / 70%)),url(${MyBackgroundImage})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-lg-4">
              <div className="banner-txt p-right-30">
                {/* <h4 className="h4-small">We have</h4> */}
                <h3>Achievements</h3>
                <p>
                Dreamworld Events has orchestrated a multitude of successful events that have left lasting impressions
.
                </p>
              </div>
            </div>
            <div className="col-md-7 col-lg-8 text-center">
              <div className="countdown">
                <div
                  id="clock"
                  className="ova_clock"
                  data-time="2019/08/17 09:00 +08:00"
                  data-day_text="Days"
                  data-hour_text="Hrs"
                  data-minute_text="Min"
                  data-second_text="Sec"
                >
                  <div className="cbox clearfix">
                    <span className="cbox-digit">20</span>{" "}
                    <span className="cbox-txt">awards</span>
                  </div>
                  <div className="cbox clearfix">
                    <span className="cbox-digit">50</span>{" "}
                    <span className="cbox-txt">Events</span>
                  </div>
                  <div className="cbox clearfix">
                    <span className="cbox-digit">20 </span>{" "}
                    <span className="cbox-txt">Tours</span>
                  </div>
                  <div className="cbox clearfix">
                    <span className="cbox-digit">40</span>{" "}
                    <span className="cbox-txt">Clients</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ----------------------------events section--------------------------------- */}

      <section>
        <div className="container">
          <div
            id="inner-page-about"
            className="row wpb_row vc_row-fluid wide-60 division"
          >
            <div className="wpb_column vc_column_container col-12 col-lg-7 col-md-8">
              <div className="vc_column-inner vc_custom_1526265638830">
                <div className="wpb_wrapper">
                  <div className="about-txt m-bottom-40 p-right-30 ">
                    <div>
                      <span className="section-id">Management</span>
                      <h4 className="h4-lg ">Working process</h4>
                    </div>
                    <div>
                    The process begins with an initial client consultation, where we delve into the client's vision, objectives, and budget. Once we have a clear understanding of their goals, we move on to the event planning phase. This includes creating a detailed event proposal, selecting venues, and assembling a team of experienced event planners, designers, and coordinators.
                    </div>
                    <div />
                    <div>
                      <ul className="features-list m-top-10">
                        <li>Define Event Objectives 
 


</li>
                        <li>Establish a Budget</li>
                        <li>Identify Target Audience </li>
                        <li>
                        Clarify Roles and Responsibilities
                        </li>
                       
                      </ul>
                    </div>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container col-12 col-lg-5 col-md-4">
              <div className="aboutImg">
                <img src={process}  alt="swing decoration"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ---------------------------why choose----------------------- */}
      <section className="why-choose">
        <div className="container">
          <div className="row">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="section-title ">
                    <span className="section-id"> Why Choose Us </span>
                    <h2 className="banner-regular">Reasons </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container col-md-4 col-sm-4">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="fbox m-bottom-40">
                    <h4 className="h4-medium">Great Service</h4>
                    <p className="m-bottom-25">
                    Event organizers in Malad are committed to deliver great service at the core of everything we do. We believe that exceptional service is the foundation of any successful event. 

                    </p>
                    <Link 
                      to="/best-event-planner-malad"
                      target="_self"
                      className="internal-link grey-color"
                    >
                      Find Out More
                     
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container col-md-4 col-sm-4">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="fbox m-bottom-40">
                    <h4 className="h4-medium">Timely Execution</h4>
                    <p className="m-bottom-25">
                    Event organizers in Malad punctuality is a priority as we understand that timing is crucial and we pride ourselves on executing every aspect of your event with precision and on schedule.

                    </p>
                    <Link
                    to="/best-event-planner-malad"
                      target="_self"
                      className="internal-link grey-color"
                    >
                      Find Out More
                    
                      {/* <i class="fas fa-arrow-right"></i> */}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column vc_column_container col-md-4 col-sm-4">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="fbox m-bottom-40">
                    <h4 className="h4-medium">Satisfied Work</h4>
                    <p className="m-bottom-25">
                    Event organizers in Malad ultimate goal is your satisfaction. We measure our success by the smiles on your guests' faces and the lasting memories we help create as your satisfaction is our most rewarding achievement.

                    </p>
                    <Link
                      to="/best-event-planner-malad"
                      target="_self"
                      className="internal-link grey-color"
                    >
                      Find Out More
                     
                      {/* <i class="fas fa-arrow-right"></i> */}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ----------------------------------email-------------------------- */}
      <section
        id="register"
        // data-vc-parallax-image="http://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/register.jpg"

        style={{
          backgroundImage:
            'url("http://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/register.jpg")',
        }}
      >
        <div className="container">
          <div className="row">
            <div>
              <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-8 vc_col-md-8">
                <div className="vc_column-inner vc_custom_1526396800346">
                  <div className="wpb_wrapper">
                    <div className="section-title1">
                      <h2 className="banner-regular">Enquire Now</h2>
                      <p className="">
                      Our friendly and knowledgeable team is here to answer all your questions if you have a specific theme in mind or need some inspiration. We are excited to work with you to create the event of your dreams. 

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpb_column vc_column_container vc_col-sm-12">
                <div className="vc_column-inner vc_custom_1526397621166">
                  <div className="wpb_wrapper">
                    <div
                      className="wpcf7 js"
                      id="wpcf7-f604-p554-o1"
                      lang="en-US"
                      dir="ltr"
                    >
                      <div className="screen-reader-response">
                        <p
                          role="status"
                          aria-live="polite"
                          aria-atomic="true"
                        />{" "}
                        <ul />
                      </div>
                      <Formik
                      initialValues={{ email: "", Name: "", Cnumber: "", event: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.Name) {
                          errors.Name = "Required";
                        } else if (values.Name.length >= 10) {
                          errors.Name = "Enter A Name";
                        }

                        if (!values.Cnumber) {
                          errors.Cnumber = "Required";
                        } else if (values.Cnumber.length >= 11) {
                          errors.Name = "Invalid Contact Number";
                        }

                        if (!values.email) {
                          errors.email = "Required";
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "Invalid email address";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        // setTimeout(() => {

                        var body =
                          '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Dreamworld Events Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' + values.Name + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' +  values.email  + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' + values.Cnumber + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Dreamworld Events</p></div></body></html>';

                        $.post(
                          "https://skdm.in/server/v1/send_lead_mail.php",
                          {
                          
                            toEmail: "dreamworldevents00@gmail.com",
                            fromEmail: "skdmlead@gmail.com",
                            bccMail: "skdmlead@gmail.com",
                            mailSubject: "New Lead genration",
                            mailBody: body,
                            accountName: "dreamworldevents",
                            accountLeadSource:
                              "",
                          },

                          function (dataa, status) {
                            // console.log('data :' + dataa);
                            // console.log("name:" + custName);
                          }
                        );

                        alert(
                          "Your Form has Submitted Our team will contact with You  soon."
                        );

                        // e.preventDefault()

                        setSubmitting(false);
                          // Reset the form after submission
  resetForm();

                        // }, 10);
                      }}
                    >
                      <Form>
              {/* <form method="" action="" id="contact-form"> */}
              {/* <div style={{ display: "none" }}>
                          <input
                            type="hidden"
                            name="_wpcf7"
                            defaultValue={604}
                          />
                          <Field
                            type="hidden"
                            name="_wpcf7_version"
                            defaultValue="5.8"
                          />
                          <Field
                            type="hidden"
                            name="_wpcf7_locale"
                            defaultValue="en_US"
                          />
                          <Field
                            type="hidden"
                            name="_wpcf7_unit_tag"
                            defaultValue="wpcf7-f604-p554-o1"
                          />
                          <Field
                            type="hidden"
                            name="_wpcf7_container_post"
                            defaultValue={554}
                          />
                          <Field
                            type="hidden"
                            name="_wpcf7_posted_data_hash"
                            defaultValue=""
                          />
                        </div> */}
                        <div id="register-form" className="row register-form">
                          <div id="input_name" className="col-md-3">
                            <p>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="name"
                              >
                                <Field
                                  size={40}
                                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="Name"
                                  defaultValue=""
                                  type="text"
                                  name="Name"
                                />
                                <ErrorMessage name="Name" component="div" />
                              </span>
                            </p>
                          </div>
                          <div id="input_email" className="col-md-3">
                            <p>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="email"
                              >
                                <Field
                                  size={40}
                                  className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control email"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="your-email@mail.com"
                                  defaultValue=""
                                  type="email"
                                  name="email"
                                />
                                  <ErrorMessage name="email" component="div" />
                              </span>
                            </p>
                          </div>
                          <div id="input_ticket" className="col-md-3">
                            <p>
                              <span
                                className="wpcf7-form-control-wrap"
                                data-name="email"
                              >
                                <Field
                                  size={40}
                                  className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control email"
                                  aria-required="true"
                                  aria-invalid="false"
                                  placeholder="Phone"
                                  defaultValue=""
                                  type="number"
                                  name="Cnumber"
                                />
                                 <ErrorMessage name="Cnumber" component="div" /> 
                              </span>
                            </p>
                          </div>
                          <div id="form_register_btn" className="col-md-3">
                            <p>
                              <input
                                className="wpcf7-form-control wpcf7-submit has-spinner btn btn-medium"
                                type="submit"
                                defaultValue="Reserve My Seat"
                              />
                              <span className="wpcf7-spinner" />
                            </p>
                          </div>
                        </div>
                        <div
                          className="wpcf7-response-output"
                          aria-hidden="true"
                        />
              </Form>
              </Formik>
                    
                       
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -------------------------partners---------------------------------- */}
      {/* <section className="pt-lg-5">
        <div className="container">
          <div className="row">
          <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="section-title ">
                    <span className="section-id"> VIEW OUR Partners </span>
                    <h2 className="banner-regular">Associate Partners.....</h2>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-1.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-2.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-3.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-4.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-5.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-6.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-7.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-8.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-9.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-11.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-10.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-13.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-12.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-15.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="sponsors-1">
                    <div className="sponsor ">
                      <div className="brand-holder">
                        <a href="#" target="_blank">
                          <img
                            className="img-fluid"
                            src="https://demo.ovathemes.com/eventador/wp-content/uploads/2018/05/sponsor-1-14.png"
                            alt="img"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};

export default Home;
