import React from "react";
import "../css/About.css";
import abt1 from "../images/about-celeb.png";
import Footer from "../components/Footer";
import MyBackgroundImage from "../images/banner.jpg";
import Carousel from "react-bootstrap/Carousel";
import {MetaTags} from "react-meta-tags";
const About = () => {
  return (
    <div>

      {/* -----------------SEO Start--------------------- */}
      <MetaTags>
        <title>Wedding Planners in Malad | Dream World Event</title>
        <meta
          name="description"
          content="Discover the finest Wedding Planners in Malad for your special day. Let our experienced team turn your dream wedding into a reality."
        />

        <meta property="og:title" content="Wedding Planners in Malad | Dream World Event" />
        <meta
          property="og:description"
          content="Discover the finest Wedding Planners in Malad for your special day. Let our experienced team turn your dream wedding into a reality."
        />
        <meta
          name="keywords"
          content="Event Organizers in Malad,
          Wedding Planners in Malad,
          Best  Event Planner in Malad,
          Decorator near Malad,
          Birthday Party Planners in Malad"
        />
        <link rel="canonical" href="/best-event-planner-malad" />
      </MetaTags>
      {/* -----------------SEO End--------------------- */}
      <div
  className="breadcrumb-option set-bg"
  data-setbg=""
  style={{ backgroundImage: `linear-gradient(to right, rgba(10, 9, 9, 0.5), rgb(0, 0, 0, 0.5)),url(${MyBackgroundImage})` }}
>
  <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center">
        <div className="breadcrumb__text">
          <h1 className="d-none">Wedding Planners in Malad </h1>
          <h2>About Us </h2>
          <div className="breadcrumb__links">
            <a href="./index.html">Home</a>
            <span>About Us</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      {/* --------------------------------about section------------------- */}

      <section>
        <div className="container">
          <div className="vc_row wpb_row vc_row-fluid wide-100">
            <div className="wpb_wrapper">
              <div className="row d-flex align-items-center ">
                <div className="col-lg-5 col-md-7 col-12">
                  <div className="about-txt m-bottom-40">
                    <span className="section-id"> About events </span>
                    <h2 className="h2-medium">
                    Create Moments

                    </h2>
                    <p>
                    Wedding Planners in Malad are dedicated to the art of creating moments that transform ordinary gatherings into extraordinary and unforgettable experiences. It's not just about planning events; it's about crafting moments that will be etched in your memory for a lifetime.


                </p>
                    <p>
                    Wedding Planners in Malad believe in the power of creativity as each event is a blank canvas waiting to be filled with vibrant ideas and unique concepts. From the initial brainstorming session to the final execution, our team infuses creativity into every aspect, ensuring that your event is not only flawlessly executed but also a true work of art.

understand that it's the little things that make a big difference. Whether it's the choice of colors, the arrangement of decor, or the seamless flow of the event, our team leaves no stone unturned in ensuring that every aspect of your event is flawlessly executed.

                    </p>
                    <div className="singnature m-top-35">
                      <p className="p-small m-bottom-20 d-none">
                      Wedding Planners in Malad believes that each event should be a reflection of your unique vision and preferences. We work closely with you to understand your desires, ensuring that your event is tailored to your personality and style. Your event is not just another project for us; it's a canvas upon which your dreams are painted.

Wedding Planners in Malad take pride in creating memories that last a lifetime. It's our mission to make every moment count, to fill your event with joy, laughter, and unforgettable memories. Your happiness is our ultimate success.

    
                      </p>
                     
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-12">
                  <div className="about-img4">
                    <img
                      className=""
                      src={abt1}
                      alt="stage decoration"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ---------------------------------you get---------------------------- */}

      <section id="hww" className=" contentRowPad">
        <div className="container">
          {/* <div className="section-title ">
            <span className="section-id"> Dreamworld Events </span>
            <h2 className="banner-regular">Why Choose Us?</h2>
          </div> */}
          <div className="row wide-100">
            <div className="col-lg-4 col-md-6 col-12 mt-2">
              <div className="audit">
                <h5>
                  <span>01.</span>Event Planning
                </h5>
                <p>
                Event planning is the backbone of every successful gathering, where imagination meets meticulous organization. We're here to turn your vision into a well-executed reality.

                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mt-2">
              <div className="audit">
                <h5>
                  <span>02.</span> Site Selection
                </h5>
                <p>
                Choosing the perfect venue is like finding the heart of your event. Our expertise in site selection ensures that your event is set against the backdrop that complements your theme and meets your practical needs.

                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mt-2">
              <div className="audit">
                <h5>
                  <span>03.</span> Execution
                </h5>
                <p>
                Execution is where the magic happens. Our team flawlessly translates plans into reality, ensuring that every detail is in place and that your event runs smoothly from start to finish.

                </p>
              </div>
            </div>
         
        
            <div className="col-lg-4 col-md-6 col-12 mt-2">
              <div className="audit">
                <h5>
                  <span>04.</span>Time consuming
                </h5>
                <p>
                We understand that planning an event can be time-consuming and demanding. Let us take the weight off your shoulders, allowing you to enjoy the journey as much as the destination.

                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mt-2">
              <div className="audit">
                <h5>
                  <span>05.</span> Budget Friendly
                </h5>
                <p>
                Achieving your dream event doesn't mean breaking the bank. We're committed to providing cost-effective solutions, making your event not only memorable but also budget-friendly.

                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mt-2">
              <div className="audit">
                <h5>
                  <span>06.</span> Proper arrangements
                </h5>
                <p>
                The devil is in the details, and proper arrangements are our forte. From seating to lighting, catering to entertainment, we leave no stone unturned to ensure everything is thoughtfully arranged.

                </p>
              </div>
            </div>
            </div>
        </div>
      </section>
      <section
  className="testimonialSection mt-lg-5 mt-2 mb-lg-5 mb-2pt-lg-5 pt-2"
  id="testimonial"
>
  <div className="titleSection ">
    <span>What they say</span>
    <h2>Testimonial</h2>
  </div>
  <Carousel>
       
    {/* <div
      className="carousel-inner"
      style={{ padding: "0 0 20px 0" }}
    
    > */}
       <Carousel.Item>
      <div className="active" style={{ padding: 20 }}>
        <div className="testimonianlBody">
          <div className="testimonialText">
            <p>
              “Dreamworld Events went above and beyond in planning and executing our charity fundraiser. Their dedication and commitment to our cause were evident in every detail. They ensured that our event was not only successful but also impactful.”
            </p>
          </div>
          <div className="testimonialName">
            <h2>--Aditi</h2>
          </div>
        </div>
      </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="">
        <div className="testimonianlBody">
          <div className="testimonialText">
            <p>
              "Dreamworld Events made our anniversary celebration an unforgettable experience. Their ability to capture the essence of our relationship and turn it into a beautifully themed event was remarkable. We were left in awe of their creativity and passion for what they do."
            </p>
          </div>
          <div className="testimonialName">
            <h2>--Khilin</h2>
          </div>
        </div>
      </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="" style={{ padding: 20 }}>
        <div className="testimonianlBody">
          <div className="testimonialText">
            <p>
              "Dreamworld Events made our daughter's sweet sixteen party a dream come true. The creativity and unique themes they offered were truly outstanding. Our guests were blown away by the stunning decorations and flawless execution. Thank you for making it a day to remember”
            </p>
          </div>
          <div className="testimonialName">
            <h2>--Rohan </h2>
          </div>
        </div>
      </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className="" style={{ padding: 20 }}>
        <div className="testimonianlBody">
          <div className="testimonialText">
            <p>
              "Dreamworld Events transformed our product launch into a spectacular event that left a lasting impression on our clients. The innovation and energy they brought to the table were unparalleled. We look forward to working with them again in the future. "
            </p>
          </div>
          <div className="testimonialName">
            <h2>--Sushil</h2>
          </div>
        </div>
      </div>
      </Carousel.Item>
   
    </Carousel>
    {/* <button
      className="carousel-control-prev"
      type="button"
      data-bs-target="#testimonialCrousel"
      data-bs-slide="prev"
    >
      {" "}
      <span className="carousel-control" aria-hidden="true">
        <i className="fa fa-angle-left" />
      </span>{" "}
      <span className="visually-hidden">Previous</span>{" "}
    </button>
    <button
      className="carousel-control-next"
      type="button"
      data-bs-target="#testimonialCrousel"
      data-bs-slide="next"
    >
      {" "}
      <span className="carousel-control" aria-hidden="true">
        <i className="fa fa-angle-right" />
      </span>{" "}
      <span className="visually-hidden">Next</span>{" "}
    </button> */}
  {/* </div> */}
  
</section>


<Footer/>
   
    </div>
  );
};

export default About;
