import React, { useState, useLayoutEffect } from "react";
import "../css/Gallery.css";
import Tabs from "./Tabs";
import Items from "./Items";
import MyBackgroundImage from "../images/banner.jpg";
import GalleryData from "../components/GalleryData";
// import Bounce from 'react-reveal/Bounce';
import Footer from "../components/Footer";
import {MetaTags} from "react-meta-tags";
const Gallery = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [data, setData] = useState(GalleryData);

  // Store Category in CategoryData
  const categoryData = GalleryData.map((value) => {
    return value.Category;
  });
  ////////////

  const tabsData = ["all", ...new Set(categoryData)];

  // for all categoryData set
  const filterCategory = (Category) => {
    if (Category == "all") {
      setData(GalleryData);
      return;
    }
    const filteredData = GalleryData.filter((value) => {
      return value.Category == Category;
    });

    setData(filteredData);
  };

  return (
    <>

{/* -----------------SEO Start--------------------- */}
<MetaTags>
        <title>Decorator near Malad | Dream World Event</title>
        <meta
          name="description"
          content="Find the perfect Decorator near Malad for your special event. Our expert decoration services will transform your occasion into a memorable celebration."
        />

        <meta property="og:title" content="Decorator near Malad | Dream World Event" />
        <meta
          property="og:description"
          content="Find the perfect Decorator near Malad for your special event. Our expert decoration services will transform your occasion into a memorable celebration."
        />
        <meta
          name="keywords"
          content="Event Organizers in Malad,
          Wedding Planners in Malad,
          Best  Event Planner in Malad,
          Decorator near Malad,
          Birthday Party Planners in Malad"
        />
        <link rel="canonical" href="/decorator-near-malad" />
      </MetaTags>
      {/* -----------------SEO End--------------------- */}

      {/* ///////////////////////////////// section 1 ////////////////////////////////// */}
      <div
        className="breadcrumb-option set-bg"
        data-setbg=""
        style={{
          backgroundImage: `linear-gradient(to right, rgba(10, 9, 9, 0.5), rgb(0, 0, 0, 0.5)),url(${MyBackgroundImage})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="breadcrumb__text">
                <h1 className="d-none">Decorator near Malad</h1>
                <h2>Gallery </h2>
                <div className="breadcrumb__links">
                  <a href="./index.html">Home</a>
                  <span>Gallery</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

      <div className="Gallery-Section-2">
        <div className="Gallery-Card">
          <div className="container">
            <div className="row">
              <div className="col-md-12 wide-100">
                {/* <---------------------tab scroller---------------> */}
               
                <Tabs filterCategory={filterCategory} tabsData={tabsData}  />
                <Items data={data} />
              </div>
            </div>
          </div>
        </div>
        <p className="d-none">Decorator near Malad invites you to witness the magic that unfolds when meticulous planning and creative design meet unforgettable moments. Our gallery showcases the diverse tapestry of events we've had the privilege to craft – from grand weddings and corporate galas to intimate gatherings and themed extravaganzas. Each image is a testament to the artistry we pour into every occasion, making it a cherished memory.*

Decorator near Malad finds a captivating array of events, each with its own unique story to tell. Our wedding collection brims with romance, elegance, and timeless beauty, as we specialize in narrating love stories through stunning visuals.

Decorator near Malad appreciates creativity and innovation as our gallery is a portal to a world of themed events, where we transform venues into enchanting wonderlands, transporting you and your guests to different realms. 

Decorator near Malad is ready to transform your dream event into a living masterpiece as it lets the moments in our gallery inspire you and take that first step towards making your event an extraordinary memory. Reach out to us today, and we'll be thrilled to add your event to our ever-growing collection of extraordinary moments.

</p>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
